import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { Header } from "../components/Header/Header";
import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  const [detail, setDetail] = useState<any>({});
  const dispatch = useDispatch();
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const getDetail = async () => {
    try {
      let data: any = await fetch(
        `https://cms.mallorca-it-service.com/api/privacy-policy-new?${
          currentLanguage === "English"
            ? "locale=en"
            : currentLanguage === "Spanish"
            ? "locale=es"
            : "locale=de"
        }`
      );
      let response = await data.json();
      setDetail(response?.data?.attributes);
      console.log(response?.data?.attributes, "xxxx1");
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDetail();
  }, [currentLanguage]);

  return (
    <div className="privacyPolicy">
      <Header />
      <Container maxWidth="lg">
        <h2>{detail?.MainTitle}</h2>
        <p className="innerTitle">{detail?.section1Heading}</p>
        <p>
          {detail?.section1Description1}
          <span className="linkColor">{detail?.section1DescriptionLink}</span>
          {detail?.section1Description2}
        </p>
        <p>
          <strong>{detail.section2Heading}</strong>
          <span className="block">{detail?.section2Description}</span>
        </p>
        <p>
          <strong>{detail.section3Heading}</strong>
          <span className="block">{detail?.section3Description}</span>
        </p>
        <p>
          <strong>{detail.section4Heading}</strong>
          <span className="block">{detail?.section4Description1}</span>
          <span className="block">{detail?.section4Description2}s</span>
        </p>
        <p>
          <strong>{detail?.section5Heading}</strong>
          <span className="block">{detail?.section5Description1}</span>
          <span className="block">
            {detail?.section5Description2}
            <span className="linkColor">
              {detail?.section5Description3Link}
            </span>
          </span>
        </p>
        <p>
          <strong>{detail?.section6Heading}</strong>
        </p>
        <p>
          <strong>{detail?.section7Heading}</strong>
        </p>
        <p>
          <strong>{detail?.section8Heading}</strong>
          <span className="block">{detail?.section8Description}</span>
        </p>
        <p>
          <strong>{detail?.section9Heading}</strong>
          <span className="block">{detail?.section9Description}</span>
        </p>
        <p>
          <strong>{detail?.section10Heading}</strong>
          <span className="block">{detail?.section10Description}</span>
        </p>
        <p>
          <strong>{detail?.section11Heading}</strong>
          <span className="block">{detail?.section11Description}</span>
        </p>
        <p>
          <strong>{detail?.section12Heading}</strong>
          <span className="block">{detail?.section12Description1}</span>
          <span className="block">{detail?.section12Description2}</span>
          <span className="block">{detail?.section12Description3}</span>
          <span className="block">{detail?.section12Description4}</span>
          <span className="block">{detail?.section12Description5}</span>
        </p>
        <p>
          <strong>{detail?.section13Heading}</strong>
          <span className="block">{detail?.section13Description}</span>
        </p>
        <p>
          <strong>{detail?.section14Heading}</strong>
        </p>
        <p>
          <strong>{detail?.section15Heading}</strong>
          <span className="block">{detail?.section15Description1}</span>
          <span className="block">{detail?.section15Description2}</span>
          <span className="block">{detail?.section15Description3}</span>
          <span className="block">{detail?.section15Description4}</span>
          <span className="block">{detail?.section15Description5}</span>
          <span className="block">{detail?.section15Description6}</span>
          <span className="block">{detail?.section15Description7}</span>
          <span className="block">{detail?.section15Description8}</span>
          <span className="block">{detail?.section15Description9}</span>
          <span className="block">{detail?.section15Description10}</span>
          <span className="block">{detail?.section15Description11}</span>
          <span className="block">{detail?.section15Description12}</span>
          <span className="block">{detail?.section15Description13}</span>
        </p>
        <p>
          <strong>{detail?.section16Heading}</strong>
          <span className="block">{detail?.section16Description1}</span>
          <span className="block">{detail?.section16Description2}</span>
        </p>
        <p>
          <strong>{detail?.section17Heading}</strong>
          <span className="block">{detail?.section17Description}</span>
        </p>
        <p>
          <strong>{detail?.section18Heading}</strong>
          <span className="block">{detail?.section18Description1}</span>
          <span className="block">{detail?.section18Description2}</span>
        </p>
        <p>
          <strong>{detail?.section19Heading}</strong>
          <span className="block">{detail?.section19Description}</span>
        </p>
        <p>
          <strong>{detail?.section20Heading}</strong>
        </p>
        <p>
          <strong className="block">{detail?.section21Heading}</strong>
          <span>{detail?.section21Description1}</span>
          <span>{detail?.section21Description2}</span>
        </p>
        <ol>
          {(detail?.section21List1 || []).map((list: string) => (
            <li>{list}</li>
          ))}
        </ol>
        <p>{detail?.section21Description3}</p>
        <p>{detail?.section21Description4}</p>

        <ol>
          {(detail?.section21List2 || []).map((list: string) => (
            <li>{list}</li>
          ))}
        </ol>
        <p>{detail?.section21Description5}</p>
        <p>{detail?.section21Description6}</p>

        <p>
          <strong>{detail?.section22Heading}</strong>
          <span className="block">{detail?.section22Description1}</span>
          <span className="block">{detail?.section22Description2}</span>
        </p>
        <p>
          <strong>{detail?.section23Heading}</strong>
          <span className="block">{detail?.section23Description1}</span>
          <span className="block">{detail?.section23Description2}</span>
        </p>
        <ol>
          {(detail?.section23List || []).map((list: string) => (
            <li>{list}</li>
          ))}
        </ol>
        <p>{detail?.section23Description3}</p>
        <p>{detail?.section23Description4}</p>

        <p>
          <strong>{detail?.section24Heading}</strong>
          <span className="block">{detail?.section24Description}</span>
        </p>
        <p>
          <strong>{detail?.section25Heading}</strong>
          <span className="block">{detail?.section25Description1}</span>
          <span className="block">{detail?.section25Description2}</span>
        </p>
        <p>
          <strong>{detail?.section26Heading}</strong>
          <span className="block">{detail?.section26Description}</span>
        </p>
        <p>
          <strong>{detail?.section27Heading}</strong>
          <span className="block">{detail?.section27Description1}</span>
          <span className="block">{detail?.section27Description2}</span>
        </p>
        <p>
          <strong>{detail?.section28Heading}</strong>
        </p>
        <p>
          <strong>{detail?.section29Heading}</strong>
        </p>
        <p>{detail?.section29Description1}</p>
        <p>{detail?.section29Description2}</p>
        <p>{detail?.section29Description3}</p>

        <p>
          <span className="block">{detail?.section29Description4}</span>
          <span className="block">{detail?.section29Description5}</span>
        </p>
        <p>{detail?.section29Description6}</p>
        <p>{detail?.section29Description7}</p>
        <p>{detail?.section29Description8}</p>
        <p>
          <strong>{detail?.section30Heading}</strong>
          <span className="block">{detail?.section30Description1}</span>
          <span className="block">{detail?.section30Description2}</span>
        </p>
        <p>
          <strong>{detail?.section31Heading}</strong>
          <span className="block">{detail?.section31Description1}</span>
          <span className="block">{detail?.section31Description2}</span>
        </p>
        <p>
          <strong>{detail?.section32Heading}</strong>
          <span className="block">{detail?.section32Description1}</span>
          <span className="block">{detail?.section32Description2}</span>
        </p>
        <p>
          <strong>{detail?.section33Heading}</strong>
          <span className="block">{detail?.section33Description1}</span>
          <span className="block">
            {detail?.section33Description2}
            <span className="linkColor"> {detail?.section33Link}</span>.
          </span>
          <span className="block">{detail?.section33Description3}</span>
        </p>
        <p>
          <strong>{detail?.section34Heading}</strong>
          <span className="block">{detail?.section34Description1}</span>
          <span className="block">{detail?.section34Description2}</span>
        </p>
        <p>{detail?.section34Description3}</p>
        <p>
          {detail?.section34Description4}
          <ol>
            {(detail?.section34List4 || []).map((list: string) => (
              <li>{list}</li>
            ))}
          </ol>
          <span className="block">{detail?.section34Description5}</span>
        </p>
        <p>
          <strong>{detail?.section35Heading}</strong>
          <span className="block">{detail?.section35Description}</span>
        </p>
        <p>
          <strong>{detail?.section36Heading}</strong>
          <span className="block">{detail?.section36Description1}</span>
        </p>
        <ol>
          {(detail?.section36List2 || []).map((list: string) => (
            <li>{list}</li>
          ))}
        </ol>
        <p>
          <span className="block">{detail?.section36Description3}</span>
          <span className="block">{detail?.section36Description4}</span>
        </p>
        <p>
          <strong>{detail?.section37Heading}</strong>
          <span className="block">{detail?.section37Description1}</span>
          <span className="block">{detail?.section37Description2}</span>
          <ol>
            {(detail?.section37List3 || []).map((list: string) => (
              <li>{list}</li>
            ))}
          </ol>
          <span className="block">{detail?.section37Description4}</span>
          <span className="block">{detail?.section37Description5}</span>
          <span className="block">{detail?.section37Description6}</span>
          <ol>
            {(detail?.section37List7 || []).map((list: string) => (
              <li>{list}</li>
            ))}
          </ol>
        </p>
        <p>
          <strong>{detail?.section38Heading}</strong>
          <span className="block">{detail?.section38Description1}</span>
          <span className="block">{detail?.section38Description2}</span>
        </p>
        <p>
          <strong>{detail?.section39Heading}</strong>
          <span className="block">{detail?.section39Description1}</span>
          <ol>
            {(detail?.section39List2 || []).map((list: string) => (
              <li>{list}</li>
            ))}
          </ol>
          <span className="block">{detail?.section39Description3}</span>
        </p>
        <p>
          <strong>{detail?.section40Heading}</strong>
          <span className="block">{detail?.section40Description1}</span>
          <span className="block">{detail?.section40Description2}</span>
          <span className="block">{detail?.section40Description3}</span>
          <span className="block">{detail?.section40Description4}</span>
        </p>
        <p>
          <strong>{detail?.section41Heading}</strong>
          <span className="block">{detail?.section41Description}</span>
        </p>
        <p>
          <strong>{detail?.section42Heading}</strong>
          <span className="block">{detail?.section42Description1}</span>
          <ol>
            {(detail?.section42List2 || []).map((list: string) => (
              <li>{list}</li>
            ))}
          </ol>
          <span className="block">{detail?.section42Description3}</span>
          <span className="block">{detail?.section42Description4}</span>
        </p>
        <p>
          <strong>{detail?.section43Heading}</strong>
          <span className="block">{detail?.section43Description1}</span>
          <span className="block">{detail?.section43Description2}</span>
        </p>
        <p>
          <strong>{detail?.section44Heading}</strong>
        </p>
        <p>{detail?.section44Description1}</p>
        <p>{detail?.section44Description2}</p>
        <p>{detail?.section44Description3}</p>
        <p>{detail?.section44Description4}</p>
        <p>{detail?.section44Description5}</p>
        <p>{detail?.section44Description6}</p>
        <p>{detail?.section44Description7}</p>
        <p>{detail?.section44Description8}</p>
        <p>{detail?.section44Description9}</p>

        <p>
          <strong>{detail?.section45Heading}</strong>
          <span className="block">{detail?.section45Description1}</span>
          <span className="block">{detail?.section45Description2}</span>
          <span className="block">{detail?.section45Description3}</span>
          <span className="block">{detail?.section45Description4}</span>
          <span className="block">{detail?.section45Description5}</span>
          <span className="block">{detail?.section45Description6}</span>
          <span className="block">{detail?.section45Description7}</span>
          <span className="block">{detail?.section45Description8}</span>
          <span className="block">{detail?.section45Description9}</span>
        </p>
        <p>
          <strong>
            <span className="block">{detail?.Footer1}</span>
            <span className="block">
              {detail?.Footer21}{" "}
              <span className="linkColor"> {detail?.Footer2Link}</span>
              {detail?.Footer22}
            </span>
          </strong>
        </p>
        <p>{detail?.Footer3}</p>
        <div className="lastSection">
          <div className="list">
            <strong>{detail?.last_section_Heading1}</strong>
            <p>{detail?.last_section_text1}</p>
            <p>{detail?.last_section_text2}</p>
            <p>{detail?.last_section_text3}</p>
            <p>{detail?.last_section_text4}</p>

            <strong>{detail?.last_section_Heading2}</strong>
            <p>{detail?.last_section_Header2_text1}</p>
            <p>{detail?.last_section_Header2_text2}</p>
            <p>{detail?.last_section_Header2_text3}</p>
            <p>{detail?.last_section_Header2_text4}</p>
          </div>

          <div className="list">
            <div className="box">
              <strong>{detail?.last_section_Header3}</strong>
              <p>{detail?.last_section_Header3_text1}</p>
              <p>{detail?.last_section_Header3_text2}</p>
              <p>{detail?.last_section_Header3_text3}</p>
              <p>{detail?.last_section_Header3_text4}</p>
            </div>
            <div className="box">
              <strong>{detail?.last_section_Header4}</strong>
              <p>{detail?.last_section_Header4_text1}</p>
              <p>{detail?.last_section_Header4_text2}</p>
              <p>{detail?.last_section_Header4_text3}</p>
              <p>{detail?.last_section_Header4_text4}</p>
            </div>
          </div>
        </div>
        <p className="last_test">{detail?.last_section_last_text}</p>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
