import { Box, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "swiper/css";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { RootState } from "../app/store";
import localHotel from "../assets/bed.svg";
import desktopWindows from "../assets/DesktopWindows.svg";
import cottage from "../assets/home.svg";
import localHospital from "../assets/LocalHospital.svg";

export const TestimonialsSlider = () => {
  const [testimonialSliderItems, setTestimonialSliderItems] = useState<any>([]);
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const fetchTestimonialSlider = async () => {
    try {
      if (currentLanguage === "English") {
        let data: any = await fetch(
          "https://cms.mallorca-it-service.com/api/testimonial-sliders?locale=de"
        );
        let response = await data.json();
        setTestimonialSliderItems(response.data);
        return response;
      } else if (currentLanguage === "Spanish") {
        let data: any = await fetch(
          "https://cms.mallorca-it-service.com/api/testimonial-sliders?locale=es"
        );
        let response = await data.json();
        setTestimonialSliderItems(response.data);
        return response;
      } else {
        let data: any = await fetch(
          "https://cms.mallorca-it-service.com/api/testimonial-sliders"
        );
        let response = await data.json();
        setTestimonialSliderItems(response.data);
        return response;
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchTestimonialSlider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  const arr3: any = [
    {
      partnerIcon: localHotel,
    },
    {
      partnerIcon: desktopWindows,
    },
    {
      partnerIcon: localHospital,
    },
    {
      partnerIcon: cottage,
    },
    {
      partnerIcon: cottage,
    },
  ];

  return (
    <Swiper className="w-100"
      grabCursor={true}
      // centeredSlides={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      slidesPerView={4}
      loop={true}
      slidesPerGroup={1}
      modules={[Autoplay, Navigation, Pagination]}
      breakpoints={{
        250: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        575: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        991: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      }}
    >
      {testimonialSliderItems?.map(
        (testimonialSliderItem: any, index: number) => (
          <SwiperSlide key={index}>
            <Link
              href={testimonialSliderItem.attributes.PartnerLink}
              className="iconCard"
            >
              <Box className="iconCard__img">
                <img
                  src={arr3[index].partnerIcon}
                  alt="icon"
                  className="img-fluid"
                />
              </Box>
              <Typography className="iconCard__title">
                {testimonialSliderItem.attributes.PartnerTitle}
              </Typography>
            </Link>
          </SwiperSlide>
        )
      )}
    </Swiper>
  );
};
