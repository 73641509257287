import { Hero } from '../components/Landing-page/Hero'
import { TopCards } from '../components/Landing-page/TopCards'
import { MidSection } from '../components/Landing-page/MidSection'

export const LandingPage = () => {
  return (
    <>
        <Hero />
        <TopCards />
        <MidSection />
    </>
  )
}
