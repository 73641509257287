import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { Header } from "../components/Header/Header";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import "./Imprint.scss";

interface Impressum {
  MainHeading?: string;
  createdAt?: string;
  updatedAt?: string;
  publishedAt?: string;
  locale?: string;
  innerTitle?: string;
  spantext1?: string;
  spantext2?: string;
  spanTelephonetext?: string;
  spantextEmail?: string;
  spanTextRedEmail?: string;
  strongSpantext?: string;
  strongSpandescription?: string;
  StrongMallorcatext?: string;
  lastStrongtext?: string;
  lastText?: string;
  spanMallorcadisplay?: string;
}

const FooterImprint = () => {
  const [detail, setDetail] = useState<Impressum>({});
  const dispatch = useDispatch();
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const getDetail = async () => {
    try {
      let data: any = await fetch(
        `https://cms.mallorca-it-service.com/api/imprent-page-contents?${
          currentLanguage === "English"
            ? "locale=en"
            : currentLanguage === "Spanish"
            ? "locale=es"
            : "locale=de"
        }`
      );
      let response = await data.json();
      setDetail(response?.data[0]?.attributes);
      console.log(response?.data[0]?.attributes);

      // console.log(response.data, "THIS IS RESPONSE DATAaaaa");
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  return (
    <div className="ImprintPage">
      <Header />
      <Container maxWidth="lg">
        <h2>{detail?.MainHeading}</h2>
        <p className="innerTitle">{detail?.innerTitle}</p>
        <p>
          <span className="block">{detail?.spantext1}</span>
          <span className="block">{detail?.spantext2}</span>
        </p>
        <p>
          <span className="block">{detail?.spanTelephonetext}</span>
          <span className="block">
            {detail?.spantextEmail}
            <span className="underline">{detail?.spanTextRedEmail}</span>
          </span>
        </p>
        <p>
          <strong>{detail?.strongSpantext}</strong>
          <span className="block">{detail?.strongSpandescription}</span>
        </p>
        <p>
          <strong>{detail?.StrongMallorcatext}</strong>
          <span className="block">{detail?.spanMallorcadisplay}</span>
        </p>
        <p>
          <strong>{detail?.lastStrongtext}</strong> {detail?.lastText}
        </p>
      </Container>
    </div>
  );
};

export default FooterImprint;
