import './App.css';
import { Footer } from './components/Footer';
import { AllRoutes } from './routes/AllRoutes';
import './app.scss'

function App() {
  return (
    <div className="App">
      <AllRoutes />
      {/* <Form /> */}
      <Footer />
    </div>
  );
}

export default App;
