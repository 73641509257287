import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import hero from "../../assets/hero-svg.png";
import { Header } from "../Header/Header";
import "./hero.scss";

interface MainContentTypes {
  TopText?: string;
  MainText?: string;
  BottomText?: string;
  Button1?: string;
  Button2?: string;
}

export const Hero = () => {
  const [mainContent, setMainContent] = useState<MainContentTypes>({});
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );

  const fetchMainContent = async () => {
    try {
      let data: any = await fetch(
        `https://cms.mallorca-it-service.com/api/main-content?${
          currentLanguage === "English"
            ? "locale=de"
            : currentLanguage === "Spanish"
            ? "local=es"
            : "locale=en"
        }`
      );
      let response = await data.json();
      setMainContent(response.data.attributes);
      // console.log(response.data, "THIS IS RESPONSE FROM TOP BAR");
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchMainContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  return (
    <Box className="hero-header">
      <Header />
      <Box className="heroModule">
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} lg={8}>
              <Box className="heroModule__content">
                <Typography className="heroModule__subtitle">
                  {mainContent.TopText || "Hey there! Hope you'r doing fine"}
                </Typography>
                <Typography className="heroModule__title">
                  {mainContent.MainText?.split(" ")
                    .splice(0, mainContent.MainText.split(" ").length - 1)
                    .join(" ")}
                  <span>
                    {" "}
                    {
                      mainContent.MainText?.split(" ")?.[
                        mainContent.MainText.split(" ").length - 1
                      ]
                    }
                  </span>
                </Typography>
                <Typography className="heroModule__text">
                  {mainContent.BottomText}
                </Typography>
                <Box className="heroModule__buttons">
                  <Button className="primaryBtn" href="#JEMIX">
                    {mainContent.Button1}
                  </Button>
                  <Button
                    className="primaryBtn primaryBtn--border"
                    href={`#${mainContent.Button2}`}
                  >
                    {mainContent.Button2}
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box className="heroModule__img"></Box>
            </Grid>
          </Grid>
        </Container>
        <Box className="heroModule__img">
          <img src={hero} alt="heroImage" className="img-fluid w-100" />
        </Box>
      </Box>
    </Box>
  );
};
