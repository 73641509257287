import {
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../assets/jemix_Logo.svg";

import { Close, Menu } from "@mui/icons-material";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../app/store";
import { setCurrentLanguage } from "../../features/language/languageSlice";
import "./header.scss";

export const Header = () => {
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch();
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const [navbarContent, setNavbarContent] = useState([]);
  const [open, setOpen] = useState<boolean>(true);
  const [selectedCountry, setSelectedCountry] = useState("English");

  useEffect(() => {
    const country = Cookies.get("country");
    if (country) {
      setSelectedCountry(country);
    }
  }, []);

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    setSelectedCountry(event.target.value as string);
    dispatch(setCurrentLanguage(event.target.value));
  };

  const handleContinue = () => {
    Cookies.set("country", selectedCountry, { expires: 30 });
    dispatch(setCurrentLanguage(selectedCountry));
    setOpen(false);
  };

  const fetchNavbarContent = async () => {
    try {
      let data: any = await fetch(
        `https://cms.mallorca-it-service.com/api/navbars?${
          currentLanguage === "English"
            ? "locale=de"
            : currentLanguage === "Spanish"
            ? "locale=es"
            : "locale=en"
        }`
      );
      let response = await data.json();
      setNavbarContent(response?.data);
      // console.log(response.data, "THIS IS RESPONSE DATAaaaa");
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchNavbarContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);
  return (
    <Box className={menu ? "mainHeader activeMenu" : "mainHeader"}>
      <Drawer anchor="top" open={open}>
        <div className="DrawerTop">
          <p>
            Choose another language to see content specific to your preference
          </p>
          <div className="DrawerTop__btnBox">
            <Select
              className="CustomSelect"
              value={selectedCountry}
              onChange={handleCountryChange}
            >
              <MenuItem value={`English`}>English</MenuItem>
              <MenuItem value={`Deutsch`}>Deutsch</MenuItem>
              {/* <MenuItem value={`Spanish`}>Spanish</MenuItem> */}
            </Select>
            <Button
              className="nextBtn"
              variant="contained"
              color="primary"
              onClick={handleContinue}
            >
              Continue
            </Button>
            <Close className="closeBtn" />
          </div>
        </div>
      </Drawer>
      <Container maxWidth="lg">
        <Box className="mainHeader__inner">
          <Box className="mainHeader__logo">
            <Link to="/">
              <Logo />
            </Link>
          </Box>

          <IconButton className="menuToggleBtn" onClick={() => setMenu(!menu)}>
            {menu ? <Close /> : <Menu />}
          </IconButton>

          <Box className="mainHeader__menu">
            {navbarContent?.map((el: any, index) => {
              return (
                <React.Fragment key={index}>
                  <a
                    href={`#${el?.attributes?.LinkName}`}
                    className="mainHeader__menu-item"
                  >
                    {el?.attributes?.LinkName}
                  </a>
                </React.Fragment>
              );
            })}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
