import React from "react";
import { Routes, Route } from "react-router-dom";
import { LandingPage } from "../pages/LandingPage";
import FooterImprint from "../pages/Imprint";
import PrivacyPolicy from "../pages/PrivacyPolicy";

export const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/imprints" element={<FooterImprint />} />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />

      <Route
        path="*"
        element={<h1>Sorry, we dont have any page like this</h1>}
      />
    </Routes>
  );
};
